import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/PostHogPageView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PHProvider","Providers"] */ "/vercel/path0/apps/web/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["TRPCReactProvider"] */ "/vercel/path0/apps/web/trpc/react.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Kalam\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"weight\":[\"400\",\"700\"],\"variable\":\"--font-kalam\"}],\"variableName\":\"kalam\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"IBM_Plex_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"variable\":\"--font-plex-sans\"}],\"variableName\":\"plex_sans\"}");
